function setInformationNoticeStatus(status) {
    if (status === 'hide') {
        $("#informationNotice").removeClass('active');
    }

    const days = 1;
    const expiry = new Date(new Date().getTime()+1000*60*60*24*days).toGMTString();
    document.cookie = "cap_informationNoticeStatus=" + status + "; expires=" + expiry + "; path=/";
    $("#informationNotice").fadeOut("fast"); // Close banner

    //remove main class, with-information-notice
    $("main").removeClass("with-information-notice"); // header height change
}