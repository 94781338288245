const navBreakpoint = 959;
const mobileNavButton = $("#mobile-nav-button");
const siteHeaderBottom = $("#site-header-bottom");
const navShade = $("#desktop-nav-screen");

const stickyDonateBtn = $("#header-sticky-donation-form-trigger");
const stickyDonateDescriptionMonthly = $(".monthly-amount-description");
const stickyDonateDescriptionSingle = $(".single-amount-description");
const stickyDonationBackdrop = $(".header-sticky-donation-form-container-backdrop");

//---------------------------//
// Mega menu expand-collapse //
//---------------------------//

$(".main-nav-list__level-0 > li > .menu-tab").on("click", function() {
  var $this = $(this);
  var $panel = $this.siblings("ul");
  var $otherBtn = $this.parent().siblings().children(".menu-tab");
  var $otherPanel = $this.parent().siblings().children("ul");

  slideAllPanels($this, $panel, $otherBtn, $otherPanel);
});

navShade.on("click", function() {
  // console.log("screen clicked");
  $(this).siblings("nav").find(".menu-tab").removeAttr("aria-expanded").removeClass("active");
  $(this).siblings("nav").find(".menu-tab").siblings("ul").slideUp("fast");
  $(this).removeClass("show");
});

//------------------------//
// Mobile expand-collapse //
//------------------------//

mobileNavButton.on("click", function() {
  var $this = $(this);
  var $panel = siteHeaderBottom;

  slidePanel($this, $panel)
});

function slidePanel(btn, panel) {
  if (btn.is(".active")) {
    btn.removeAttr("aria-expanded").removeClass("active");
    panel.slideUp("fast");
    unlockBody(panel);
    //hideShade();
  } else {
    btn.attr("aria-expanded", "true").addClass("active");
    panel.slideDown("fast");
    lockBody(panel);
    //showShade();
  }
}

function slideAllPanels(btn, panel, otherBtn, otherPanel) {
  if (btn.is(".active")) {
    btn.removeAttr("aria-expanded").removeClass("active");
    panel.slideUp("fast");
    hideShade();
  } else {
    btn.attr("aria-expanded", "true").addClass("active");
    panel.slideDown("fast");
    otherBtn.attr("aria-expanded", "false").removeClass("active");
    otherPanel.slideUp("fast");
    showShade();
    // on desktop if scroll close menu
    if (panel.width() > navBreakpoint) {
      $(window).on("scroll", (function() {
        btn.removeAttr("aria-expanded").removeClass("active");
        panel.slideUp("fast");
        hideShade();
      }));
    }
  }
}

function showShade() {
  navShade.addClass("show");
}

function hideShade() {
  navShade.removeClass("show");
}

//-----------------------------//
// Resizing across breakpoints //
//-----------------------------//

var prevWindowWidth = 0;

$(document).ready(function () {
  prevWindowWidth = $(window).width();
});

$(window).resize(function () {
  var currentWindowWidth = $(window).width();
  // Desktop to mobile
  if (prevWindowWidth > navBreakpoint && currentWindowWidth <= navBreakpoint) {
    siteHeaderBottom.css("display", "none");
    mobileNavButton.removeAttr("aria-expanded").removeClass("active");
  // Mobile to desktop
  } else if (prevWindowWidth <= navBreakpoint && currentWindowWidth > navBreakpoint) {
    siteHeaderBottom.css("display", "");
    $(".main-nav-list__level-0 > li > .menu-tab").removeAttr("aria-expanded").removeClass("active");
    $(".main-nav-list__level-0 > li > .menu-tab + ul ").slideUp("fast");
    navShade.removeClass("show");
    unlockBody(siteHeaderBottom);
  }

  prevWindowWidth = currentWindowWidth;
});

//-----------------------------//
// sticky header //
//-----------------------------//

var header = $(".site-header"),
    oldScrollPos = 0;

$(window).on("scroll resize load", (function() {
  // headerOffset is used when header doesn't start at top of viewport, eg WJ home page
  var headerHeight = header.outerHeight(),
  currentScrollPos = $(window).scrollTop();
  // if top
  if (currentScrollPos == 0) {
    header.addClass("header--show").removeClass("site-header--box-shadow").css("top", "0");
  }
  // if scroll up
  else if (currentScrollPos < oldScrollPos) {
    header.removeClass("header--hide").addClass("header--show site-header--box-shadow").css({"position":"fixed", "top":"0"});
  }
  // if scroll down
  else {
    header.removeClass("site-header--box-shadow");
    // if still showing a bit of header
    if (currentScrollPos <= headerHeight) {
      header.removeClass("header--hide").addClass("header--show").css({"position":"absolute", "top":"0"});
    } else {
      header.addClass("header--hide").removeClass("header--show").css("top", -headerHeight);
    }
  }

  oldScrollPos = currentScrollPos;
}));

//-----------------------------//
// unlock body //
//-----------------------------//

function lockBody(navPanels) {
  var body = $('body'),
      header = $('header'),
      bodyScrollPos = $(window).scrollTop(),
      bodyWidthBeforeNavOpen = $('body').width();

  body.css({
      'position': 'fixed',
      'top': -bodyScrollPos + 'px',
      'left': '0',
      'right': '0'
  });
  // if there was a scrollbar on page, add some padding of same width to body so whole page doesn't shift
  var scrollbarWidth = $('body').width() - bodyWidthBeforeNavOpen;
  // also add to header and nav panels so they don't shift around
  if (scrollbarWidth > 0) {
      body.add(header).add(navPanels).css({
          'paddingRight': scrollbarWidth + 'px'
      });
  }
}

function unlockBody(navPanels) {
  var body = $('body'),
      header = $('header'),
      bodyScrollPos = -parseInt(body.css('top'));
  // if body isn't already locked, don't do anything
  if (body.css('position') != 'fixed') return;
  // reset the styles we added to body, header and nav panels when we locked the page
  body.css({
      'position': '',
      'top': '',
      'left': '',
      'right': '',
      'paddingRight': ''
  });
  header.add(navPanels).css({
      'paddingRight': ''
  });

  $(window).scrollTop(bodyScrollPos);
  // console.log('unlocked body');
}

//-----------------------------//
// sticky donation button appear on scroll //
//-----------------------------//

$(function() {
  $(window).scroll(function() {    
      var scroll = $(window).scrollTop();
      if (scroll >= 300) {
        stickyDonateBtn.addClass("visible");
      } else {
        stickyDonateBtn.removeClass("visible");
      }
  });
});

//-----------------------------//
// when the sticky donation button is clicked, toggle the backdrop classes //
//-----------------------------//

stickyDonateBtn.on("click", function() {
  $(this).toggleClass("active")
  $(".header-sticky-donation-form").toggleClass("active");
  $(".header-sticky-donation-form-container-backdrop").toggleClass("overlay");
  $("body").toggleClass("freezePage");
});

stickyDonationBackdrop.on("click", function() {
  $(this).removeClass("overlay")
  $(".header-sticky-donation-form").removeClass("active");
  $(".header-sticky-donation-form-trigger").removeClass("active");
  $("body").removeClass("freezePage");
});

//-----------------------------//
// sticky donation form amount descriptions //
//-----------------------------//

$(".monthly-amount-1").on("click", function() {
  $(stickyDonateDescriptionMonthly).removeClass("active");
  $(".monthly-amount1-description").addClass("active");
});
$(".monthly-amount-2").on("click", function() {
  $(stickyDonateDescriptionMonthly).removeClass("active");
  $(".monthly-amount2-description").addClass("active");
});
$(".monthly-amount-3").on("click", function() {
  $(stickyDonateDescriptionMonthly).removeClass("active");
  $(".monthly-amount3-description").addClass("active");
});

$(".single-amount-1").on("click", function() {
  $(stickyDonateDescriptionSingle).removeClass("active");
  $(".single-amount1-description").addClass("active");
});
$(".single-amount-2").on("click", function() { 
  $(stickyDonateDescriptionSingle).removeClass("active");
  $(".single-amount2-description").addClass("active");
});
$(".single-amount-3").on("click", function() {
  $(stickyDonateDescriptionSingle).removeClass("active");
  $(".single-amount3-description").addClass("active");
});

//-----------------------------//
// Don't show on donation or appeal pages //
//-----------------------------//

$(document).ready(function() {
  if (window.location.href.indexOf("donate") > -1 || window.location.href.indexOf("appeals") > -1 || window.location.href.indexOf("help-with-money-and-debt") > -1) {
    $(".header-sticky-donation-form-container").hide();
  } 
});