const searchParams = new URLSearchParams(window.location.href);
const postCode = searchParams.get('postcode');
const formType = searchParams.get('form-type');

const postCodeForm = (form) => `
	<form class="get-help-postcode-form variation-postcode-form" action="https://capuk.org/get-help/results" accept-charset="UTF-8">
	    <input type="hidden" name="step-two" value="show" />
	    <input type="hidden" name="form-type" value="${form}" />
	    <div class="postcode-field postcode-field--invert">
	        <label class="visually-hidden" for="postcode">Your postcode</label>
	
	        <p class="error-message" style="display: none">
	            <svg class="error-message__icon" width="16" height="16" viewBox="0 0 16 16" fill="none">
	                <path d="M8-.003a8 8 0 100 16 8 8 0 000-16zm1.5 13.5h-3v-3h3v3zm-.5-4H7l-.5-7h3l-.5 7z" fill="currentColor"></path>
	            </svg>
	            Please use a valid postcode format (e.g. AB1&nbsp;2CD).&nbsp;
	        </p>
	
	        <div class="postcode-inputs postcode-inputs--inline">
	            <input class="postcode-input input--invert" type="text" name="postcode" value="${postCode ?? ''}" placeholder="Enter your postcode" maxlength="8" required="" />
	
	            <input class="postcode-submit button button--green" type="submit" value="Search" />
	        </div>
	    </div>
	</form>
`;

const postCodeFormSection = (form) => `
	<div class="cta-block cta-block--inline" id="postcode">
	    <div class="cta-block__postcode lc--nbm">
	        <h2 class="cta-block__heading heading-lg">Find help near you</h2>
	        ${postCodeForm(form)}
	    </div>
	</div>
`;

/*
 \*
  * END POSTCODE COMPONENT
 \*
 */

/*
 \*
  * NAV CHANGES
 \*
 */

const nav = document.querySelector('.main-nav');

// Change text in nav
const getHelpNearYouMenu = nav.querySelector('[aria-label="Get help near you"]');
const getHelpNearYouMenuListItem = getHelpNearYouMenu.querySelector('li');
const getHelpExerpt = getHelpNearYouMenuListItem.querySelector('.excerpt');
getHelpExerpt.textContent = 'Find free, accredited and friendly services available in your area to get the help you need.';
getHelpExerpt.style.marginBottom = "24px";

// Add postcode form
getHelpExerpt.insertAdjacentHTML('afterend', postCodeForm('navigation_menu_postcode_search'));



//Tracking Script
window.dataLayer = window.dataLayer || [];
window.dataLayer.push({ 'event': 'Postcode Search Submit',});
// CONSTANT 'Postcode' : 'BN2 76X', // Postcode entered into form field 'PostcodeBlock' : 'Global', // DebtHelp etc 'PostcodeFormType' : 'Navigation Menu', // Postcode form design type 'DebCentreResults:' : 2, // Number of debt centre results shown to user e.g. 1 / 2 / 3 etc 'JobClubResults:': 4, // Number of job club results 'LifeSkillsResults' : 6, // Number of life skill results 'MoneyCoachingResults' : 6, // Number of Money Coaching 'TotalResults' : 12 // Total number of results returned 